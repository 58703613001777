import React, { useState } from 'react'
import { Link, withPrefix } from 'gatsby'
import styled from 'styled-components'

import {
    Layout,
    SEO,
    Container,
    PageTitle,
    LocaleConfig,
    useTranslations,
    SectionSpacer,
} from '../core'

interface HRFAQCategory {
    title: string
    faqs: Array<{
        title: string
        path: string
        body: string
    }>
}

interface IndexPageProps {
    location: any
    path: string
    pageContext: {
        localeConfig: LocaleConfig
        categories: Array<HRFAQCategory>
    }
}

const HRFAQOverviewPage = ({ location, pageContext, path }: IndexPageProps) => {
    const [activeCategory, setActiveCategory] = useState<string | null>(
        location.hash?.substring(1)
    )
    const { localeConfig, categories } = pageContext
    const { translate } = useTranslations(localeConfig.locale)

    return (
        <Layout
            locale={localeConfig}
            path={path}
            hasContactSection={false}
            hasFaqIndex
        >
            <SEO title={translate('page_hr-faq')} />
            <FAQPageTitle hasSectionSpacer={false}>
                {translate('page_hr-faq')}
            </FAQPageTitle>
            <CategoriesIndexContainer>
                <CategoriesIndex>
                    {categories.map((category) => (
                        <CategoriesIndexCategory
                            key={category.title}
                            to={`#${category.title}`}
                            isActive={category.title === activeCategory}
                            onClick={() => setActiveCategory(category.title)}
                        >
                            {translate(`hrfaq_category_${category.title}`)}
                        </CategoriesIndexCategory>
                    ))}
                </CategoriesIndex>
            </CategoriesIndexContainer>
            <Container>
                <Categories>
                    {categories.map((category) => {
                        if (category.faqs.length === 0) {
                            return null
                        }

                        return (
                            <Category key={category.title} id={category.title}>
                                <CategoryTitle>
                                    {translate(
                                        `hrfaq_category_${category.title}`
                                    )}
                                </CategoryTitle>
                                {category.faqs.map((faq) => (
                                    <FAQ key={faq.path} to={faq.path}>
                                        <FAQQuestionIcon />
                                        <FAQQuestion>{faq.title}</FAQQuestion>
                                        <FAQCaret />
                                    </FAQ>
                                ))}
                            </Category>
                        )
                    })}
                </Categories>
            </Container>
            <SectionSpacer />
        </Layout>
    )
}

export default HRFAQOverviewPage

const FAQPageTitle = styled(PageTitle)`
    @media ${({ theme }) => theme.breakpoints.phone_only} {
        height: auto;
    }

    h1 {
        @media ${({ theme }) => theme.breakpoints.phone_only} {
            text-align: left;
            padding: 24px 12px;
            font-size: 20px;
            line-height: 30px;
        }
    }
`

const CategoriesIndexContainer = styled.div`
    position sticky;
    top: 94px;
    display: flex;
    justify-content: center;
    width: 100%;
    background: ${({ theme }) => theme.colors.lightBackground};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        top: 50px;
        padding: 0 12px;
    }
`
const CategoriesIndex = styled.div`
    width: 100%;
    max-width: 960px;
    display: flex;
    flex-direction: row;
    padding: 24px 0;
    overflow-x: scroll;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 16px 0;
    }
`

const CategoriesIndexCategory = styled(({ isActive, theme, ...props }) => (
    <Link {...props} />
))`
    flex-shrink: 0;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    cursor: pointer;
    border-radius: 80px;
    margin-right: 12px;
    text-decoration: none;
    color: ${({ isActive, theme }) =>
        isActive ? theme.colors.invertedText : theme.colors.text};
    background: ${({ isActive, theme }) =>
        isActive ? theme.colors.invertedBackground : 'transparent'};
    font-family: ${({ theme }) => theme.typography.boldFontFamily};

    &:hover {
        background: ${({ theme }) => theme.colors.invertedBackground};
        color: ${({ theme }) => theme.colors.invertedText};
    }
`

const Categories = styled.div`
    padding-top: 48px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding-top: 18px;
    }
`

const Category = styled.div`
    margin-bottom: 80px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        margin-bottom: 48px;
    }
`

const CategoryTitle = styled.h2`
    font-family: ${({ theme }) => theme.typography.boldFontFamily};
    font-weight: 400;
    font-size: 28px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 18px;
        line-height: 24px;
        padding: 0 16px;
    }
`

const FAQ = styled(Link)`
    display: flex;
    align-items: center;
    padding 36px 20px 20px 8px;
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
    
    @media ${({ theme }) => theme.breakpoints.phone_only} {
        padding: 20px 0 8px 0;
    }
`

const FAQQuestion = styled.div`
    flex: 1 1 auto;
    font-family: ${({ theme }) => theme.typography.boldFontFamily};
    font-size: 20px;
    line-height: 30px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        font-size: 16px;
        line-height: 24px;
    }
`

const FAQQuestionIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    margin-right: 40px;
    font-size: 28px;
    font-family: ${({ theme }) => theme.typography.boldFontFamily};
    border-radius: 100%;
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.accent};

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        width: 24px;
        height: 24px;
        font-size: 14px;
        margin-right: 8px;
    }

    &:before {
        content: '?';
    }
`

const FAQCaret = styled.div`
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    color: ${({ theme }) => theme.colors.mutedText};
    margin-left: 50px;

    @media ${({ theme }) => theme.breakpoints.phone_only} {
        margin-left: 8px;
    }

    &:before {
        display: block;
        content: ' ';
        width: 100%;
        height: 100%;
        background-image: url('${withPrefix('/images/icons/caret-right.svg')}');
        background-repeat: no-repeat;
        background-position: center;
    }
`
